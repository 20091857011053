import { EContainerVariant, toImageProps, Container } from '@front/shared/ds';
import { Media } from '@shared/master-types';
import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import React, { useRef } from 'react';

export type TCustomImagesRow = {
  customType: 'space-affiliate-program-image';
  images: {
    image: string | Media;
    id?: string;
  }[];
  id?: string;
  blockName?: string;
  blockType: 'custom-images';
};

type TCustomSpaceImageRowProps = {
  row: TCustomImagesRow;
};

export const CustomSpaceImageRow: React.FC<TCustomSpaceImageRowProps> = ({
  row,
}) => {
  const imageWrapperRef = useRef<HTMLDivElement>(null);

  if (!row.images.length) {
    return null;
  }

  const nextImageProps: ImageProps | null = toImageProps(row.images[0].image);

  if (!nextImageProps) {
    return null;
  }

  return (
    <Container variant={EContainerVariant.Lead} isPadding={false}>
      <div
        ref={imageWrapperRef}
        className='scrollbar-hidden flex justify-start overflow-auto'
      >
        <Image
          className={clsx('m-auto max-w-none ')}
          {...nextImageProps}
          alt={nextImageProps.alt}
        />
      </div>
    </Container>
  );
};
